import useTranslate from "app/hooks/useTranslate";
import NextStepIcon from "assets/images/button-next-step.svg";
import { BOOLEAN_VALUES } from "constants/input-fields";
import { useRef } from "react";
import { useSelector } from "react-redux";
import "./index.scss";

const Button = (props) => {
    const { isNext, tabIndex = 0, onClick, children, disabled = false, isPopup = false } = props;
    const translate = useTranslate();
    const buttonRef = useRef();
    const loaderState = useSelector((store) => store.loaderState);
    const requestingState = useSelector((store) => store.requestingState);
    const isLastStepValue = useSelector((store) => store.wizardSteps.isLastStep);
    const isLastStep = isLastStepValue === BOOLEAN_VALUES.true;

    const handleButtonClick = (e) => {
        // this is to "de-select" the button after it's clicked
        buttonRef.current.blur();

        if ((disabled || isLastStep || loaderState) && !isPopup) {
            return;
        }

        onClick(e);
    };

    return (
        <button
            className="button-wrapper"
            tabIndex={tabIndex}
            ref={buttonRef}
            onClick={handleButtonClick}
            disabled={(disabled || isLastStep || requestingState || loaderState) && !isPopup}
        >
            {isNext ? (
                <div className="button-next-wrapper">
                    {children ? children : <span>{translate("button_next_step")}</span>}
                    <div className="button-arrow">
                        <img src={NextStepIcon} alt={translate("button_next_step")} />
                    </div>
                </div>
            ) : (
                <div className="button-text-only">{children}</div>
            )}
        </button>
    );
};

export default Button;
