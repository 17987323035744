import useTranslate from "app/hooks/useTranslate";
import Background from "assets/images/begin-process-bg.svg";
import growLogo from "assets/images/grow-logo.svg";
import WizardSteps from "components/wizard_steps";
import { useSelector } from "react-redux";
import "./index.scss";

const DesktopContainer = ({ children }) => {
    const translate = useTranslate();
    const logoAlt = translate("grow_logo_alt");
    const isAu10tix = useSelector((store) => store.isAu10tix);
    const showWizardSteps = !Boolean(useSelector((store) => store.wizardSteps.hideSteps));

    if (isAu10tix) {
        return <div className={"au10tix-desktop-container-wrapper"}>{children}</div>;
    }

    return (
        <div className="desktop-container-wrapper">
            <div className="desktop-container-box-wrapper">
                <div className="desktop-container-right-box">
                    <div className="background-wrapper">
                        <img src={Background} />
                    </div>

                    <div className="desktop-container-top-section">
                        <div className="desktop-container-logo-wrapper">
                            <img src={growLogo} alt={logoAlt} />
                        </div>

                        <div className="desktop-container-text-header">{translate("signup_header_text")}</div>

                        <div className="desktop-container-text-subheader">{translate("signup_header_sub_text")}</div>
                    </div>

                    {showWizardSteps && <WizardSteps />}
                </div>
                <div className="desktop-container-left-box">{children}</div>
            </div>
        </div>
    );
};

export default DesktopContainer;
