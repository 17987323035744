import useTranslate from "app/hooks/useTranslate";
import { useEffect, useMemo, useRef } from "react";
import HtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import "./index.scss";

const DIGITS_REGEX = new RegExp(/^\d+$/);

const OtpInput = (props) => {
    const { value, numberOfInputs, showError, errorMessage, onChange, label, subLabel, enterCodeLabel, isResendOtp } = props;

    const firstInputRef = useRef();
    const popupsArray = useSelector((store) => store.popupsArray);
    const requestingState = useSelector((store) => store.requestingState);
    const loaderState = useSelector((store) => store.loaderState);

    const valueItems = useMemo(() => {
        const valueArray = value.split("");
        const items = [];

        for (let i = 0; i < numberOfInputs; i++) {
            const char = valueArray[i];

            if (DIGITS_REGEX.test(char)) {
                items.push(char);
            } else {
                items.push("");
            }
        }

        return items;
    }, [value, numberOfInputs]);

    useEffect(() => {
        // IF VALUE IS EMPTY (AFTER RESENDING A NEW OTP OR AFTER ENTERING A WRONG CODE) => FOCUS THE FIRST INPUT
        if (value.trim().length === 0) {
            firstInputRef?.current?.focus();
        }
    }, [value, loaderState]);

    useEffect(() => {
        if (!loaderState) {
            firstInputRef?.current?.focus();
        }
    }, [loaderState]);

    const focusToNextInput = (target) => {
        const nextElementSibling = target.nextElementSibling;

        if (nextElementSibling) {
            nextElementSibling.focus();
            return;
        }

        target.blur();
    };

    const focusToPrevInput = (target) => {
        const previousElementSibling = target.previousElementSibling;

        if (previousElementSibling) {
            previousElementSibling.focus();
        }
    };

    const inputOnChange = (e, idx) => {
        if (popupsArray.length > 0 || isResendOtp) {
            return;
        }

        const target = e.target;
        let targetValue = target.value.trim();
        const isTargetValueDigit = DIGITS_REGEX.test(targetValue);

        if (!isTargetValueDigit && targetValue !== "") {
            return;
        }

        const nextInputEl = target.nextElementSibling;

        // only delete digit if next input element has no value
        if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== "") {
            return;
        }

        targetValue = isTargetValueDigit ? targetValue : " ";

        const targetValueLength = targetValue.length;

        if (targetValueLength === 1) {
            const newValue = value.substring(0, idx) + targetValue + value.substring(idx + 1);

            onChange(newValue);

            if (!isTargetValueDigit) {
                return;
            }

            focusToNextInput(target);
        } else if (targetValueLength === numberOfInputs) {
            onChange(targetValue);

            target.blur();
        }
    };

    const inputOnKeyDown = (e) => {
        const { key } = e;
        const target = e.target;

        if (key === "ArrowRight" || key === "ArrowDown") {
            e.preventDefault();
            return focusToNextInput(target);
        }

        if (key === "ArrowLeft" || key === "ArrowUp") {
            e.preventDefault();
            return focusToPrevInput(target);
        }

        const targetValue = target.value;

        // keep the selection range position if the same digit was typed
        target.setSelectionRange(0, targetValue.length);

        if (e.key !== "Backspace" || targetValue !== "") {
            return;
        }

        focusToPrevInput(target);
    };

    const inputOnFocus = (e) => {
        const { target } = e;

        // keep focusing back until previous input element has value
        const prevInputEl = target.previousElementSibling;

        if (prevInputEl && prevInputEl.value === "") {
            return prevInputEl.focus();
        }

        target.setSelectionRange(0, target.value.length);
    };

    return (
        <div className={"otp-input-wrapper"}>
            {label && <label className="otp-input-label">{label}</label>}
            {subLabel && <label className="otp-input-sub-label">{subLabel}</label>}
            {enterCodeLabel && <div className="otp-input-enter-code">{enterCodeLabel}</div>}

            <div className="otp-input-error-wrapper">
                <div className={"otp-input-inputs"}>
                    {valueItems.map((digit, idx) => {
                        return (
                            <input
                                key={idx}
                                type="text"
                                inputMode="numeric"
                                autoComplete="one-time-code"
                                pattern="\d{1}"
                                maxLength={numberOfInputs}
                                className={"otp-input-value"}
                                value={digit}
                                onChange={(e) => inputOnChange(e, idx)}
                                onKeyDown={inputOnKeyDown}
                                onFocus={inputOnFocus}
                                {...(idx === 0 ? { autoFocus: true, ref: firstInputRef } : {})}
                                disabled={isResendOtp || requestingState || loaderState}
                            />
                        );
                    })}
                </div>

                {showError && <div className="otp-input-error-message">{errorMessage}</div>}
            </div>

            <ResendOtp resendData={props} />
        </div>
    );
};

const ResendOtp = ({ resendData }) => {
    const { isResendOtp, resendOtpFunc, resendOtpObject, sendingOtpText, canResend } = resendData;

    const generalDeclaration = useSelector((store) => store.gd);
    const gdParams = generalDeclaration.ErrorsLangParam.parameters;
    const blockedMinutes = parseInt(gdParams.blocked_minutes ?? 10);
    const translate = useTranslate();
    const recaptchaDisclaimer = translate("google_recaptcha_disclaimer");

    const getCantResendOtp = () => translate("otp_cant_resend_code_text").replace("{{minutes}}", blockedMinutes);

    return (
        <>
            <div className="recaptcha-disclaimer-text">{HtmlParser(recaptchaDisclaimer)}</div>

            {!canResend && !isResendOtp && (
                <div className="otp-input-resend">
                    <span className="otp-input-resend-disabled">{getCantResendOtp()}</span>
                </div>
            )}

            {canResend && (
                <div className="otp-input-resend">
                    <span className="otp-input-resend-text">{resendOtpObject.main}</span>

                    {isResendOtp ? (
                        <div className="otp-input-resend-in-progress-wrapper">
                            <div className="otp-input-resend-loader"></div>
                            <div className="otp-input-resend-in-progress">{sendingOtpText}</div>
                        </div>
                    ) : (
                        <span className="otp-input-resend-action" onClick={resendOtpFunc}>
                            {resendOtpObject.action}
                        </span>
                    )}
                </div>
            )}
        </>
    );
};

export default OtpInput;
