import { previousPageObject } from "app/functions";
import useTranslate from "app/hooks/useTranslate";
import { BOOLEAN_VALUES } from "constants/input-fields";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router";
import backArrow from "../../assets/images/back-arrow-black.svg";
import "./index.scss";

const RouteWrapper = () => {
    const [containerClassName, setContainerClassName] = useState("");
    const wizardSteps = useSelector((store) => store.wizardSteps);
    const isDesktop = useSelector((store) => store.deviceState.isDesktop);
    const translate = useTranslate();
    const location = useLocation();
    const navigate = useNavigate();

    const isLastStep = wizardSteps?.isLastStep === BOOLEAN_VALUES.true;
    const isBackDisabled = wizardSteps?.isBackDisabled === BOOLEAN_VALUES.true;
    const backFlexStart = wizardSteps?.backFlexStart === BOOLEAN_VALUES.true;

    const handleBackButtonClick = () => {
        let result = previousPageObject(location);

        if (Object.keys(result).length > 0) {
            let mainPath = result?.main;
            let subRoutePath = result?.sub;

            if (mainPath && subRoutePath) {
                let fullPath = `/${mainPath}/${subRoutePath}`;
                navigate(fullPath);
            }
        }
    };

    return (
        <div className={`step-form-container ${containerClassName || ""}`}>
            {isDesktop && wizardSteps.currentStep > 1 && !isLastStep && !isBackDisabled && (
                <div {...(backFlexStart ? { className: "back-button-flex-start" } : {})}>
                    <button className="step-form-back-arrow" onClick={handleBackButtonClick}>
                        <img src={backArrow} alt={translate("back_button_alt_text")} />
                    </button>
                </div>
            )}

            <div className={`step-form-wrapper${wizardSteps.currentStep === 1 || isLastStep || isBackDisabled ? " step-form-arrow-placeholder" : ""}`}>
                <Outlet context={[setContainerClassName]} />
            </div>
        </div>
    );
};

export default RouteWrapper;
