import Api from "api/requests";
import { generateDefaultFormData, isNumber, navigateToNextStep } from "app/functions";
import useApiError from "app/hooks/useApiError";
import useTranslate from "app/hooks/useTranslate";
import useValidate from "app/hooks/useValidate";
import Button from "components/forms/button";
import TextInput from "components/forms/textInput";
import HeaderTitle from "components/header_title";
import OtpInput from "components/otp_input";
import Subheader from "components/subheader";
import { validateRegistrationData } from "constants/data-validation";
import { BOOLEAN_VALUES, DELAY_DEBOUNCE, POPUP_PAYLOADS } from "constants/input-fields";
import { mainRootRoutes } from "constants/main-routes";
import popupTypes from "constants/popup-types";
import routesMap from "constants/route-map";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Actions from "redux/actions";
import "./index.scss";

const OtpValidation = ({ fields, location }) => {
    const defaultFormData = generateDefaultFormData(fields);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const translate = useTranslate();
    const handleServerError = useApiError();
    const leadId = useSelector((store) => store.leadId);
    const signupForm = useSelector((store) => store.signupForm);

    const [firstTry, setFirstTry] = useState(true);
    const [formData, setFormData] = useState(defaultFormData);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [otpCode, setOtpCode] = useState("");
    const [canResend, setCanResend] = useState(true);
    const [isNumberDisabled, setIsNumberDisabled] = useState(false);
    const [otpToken, setOtpToken] = useState("");
    const [isResendOtp, setIsResendOtp] = useState(false);
    const otpTimeoutRef = useRef();

    const otpStateTimeout = useSelector((store) => store.otpState.timeout);
    const otpStateToken = useSelector((store) => store.otpState.otpToken);
    const generalDeclaration = useSelector((store) => store.gd);
    const gdParams = generalDeclaration.ErrorsLangParam.parameters;

    // * LENGTH OF OTP CODE
    const otpInputs = parseInt(gdParams.otp_len);

    // * HOW MANY DIGITS OF THE PHONE NUMBER SHOULD BE SHOWN
    const numberOfShownDigits = parseInt(gdParams.number_of_shown_digits_otp ?? 3);

    // * NUMBER OF ALLOWED ATTEMPTS PER OTP TOKEN
    const allowedAttemptsPerToken = parseInt(gdParams.token_allowed_attempts);
    const [numberOfOtpTries, setNumberOfOtpTries] = useState(0);

    // * NUMBER OF ALLOWED OTP CODE RESEND
    const allowedNumberOfResend = parseInt(gdParams.otp_resend_attempts);
    const [numberOfResend, setNumberOfResend] = useState(0);

    // * HOW LONG TO BLOCK THE USER FROM GETTING A NEW OTP CODE
    const blockedMinutes = parseInt(gdParams.blocked_minutes ?? 10);
    const blockingTimeout = blockedMinutes * 60 * 1000;

    const validationObj = { defaultFormData, formData, setFormData, location };
    const validateForm = useValidate(validationObj);

    useEffect(() => {
        if (numberOfOtpTries >= allowedAttemptsPerToken) {
            handleTooManyOtpTries();
        }
    }, [numberOfOtpTries]);

    useEffect(() => {
        if (numberOfResend >= allowedNumberOfResend) {
            setCanResend(false);
        }
    }, [numberOfResend]);

    useEffect(() => {
        if (!canResend) {
            setTimeout(() => {
                setCanResend(true);
                setNumberOfResend(0);
            }, blockingTimeout);
        }
    }, [canResend]);

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            validateForm(false);
        }, DELAY_DEBOUNCE);

        return () => clearTimeout(delayDebounce);
    }, [signupForm]);

    useEffect(() => {
        smsOtpCodeListener();

        // * CHECK IF OTP TIMEOUT EXISTS IN REDUX => IF SO, SET TIMEOUT AND DISABLE NEXT STEP
        const timeoutFromRedux = otpStateTimeout;
        const currentTime = Date.now();

        if (timeoutFromRedux > currentTime) {
            const diff = timeoutFromRedux - currentTime;

            setFirstTry(true);
            setIsOtpSent(false);
            setIsNumberDisabled(true);

            otpTimeoutRef.current = setTimeout(() => {
                setIsNumberDisabled(false);
                setNumberOfOtpTries(0);
                dispatch(Actions.setOtpStateTimeout(false));
            }, diff);
        }

        // * CHECK TO SEE IF TOKEN IS AVAILABLE IN REDUX => IF SO, SET STATE TO THE TOKEN
        // * WE HAVE THIS BECAUSE OF AN EDGE CASE WHEN AN OTP IS SENT AND THE APP CONTAINER (MOBILE/DESKTOP) HAS CHANGED
        // * THIS WILL CAUSE THE STATES TO DEFAULT BACK TO ORIGINAL VALUES, HENCE SHOWING AS IF AN OTP HASN'T BEEN SENT
        if (otpStateToken) {
            setOtpToken(otpStateToken);
            setIsOtpSent(true);
        }

        return () => clearTimeout(otpTimeoutRef.current);
    }, []);

    const handleTooManyOtpTries = () => {
        setFirstTry(true);
        setIsOtpSent(false);
        setIsNumberDisabled(true);
        dispatch(Actions.setOtpStateTimeout(getTimeoutValidity()));
        dispatch(Actions.setOtpStateToken(false));

        const popupPayload = {
            ...POPUP_PAYLOADS.tooManyOtpRetries,
            callback: () => {},
        };

        dispatch(
            Actions.addPopup({
                type: popupTypes.GENERAL_MESSAGE,
                payload: popupPayload,
            })
        );

        otpTimeoutRef.current = setTimeout(() => {
            setIsNumberDisabled(false);
            setNumberOfOtpTries(0);
            dispatch(Actions.setOtpStateTimeout(false));
        }, blockingTimeout);
    };

    const getTimeoutValidity = () => {
        const timeout = Date.now() + blockingTimeout;
        return timeout;
    };

    const handleInputChange = (e) => {
        let type = e?.target?.type;
        let name = e?.target?.name || e?.name;
        let val = e?.target?.value || e?.value;
        let key = formData[name]?.inputKey;

        if (type === "checkbox") {
            let isChecked = e.target.checked;
            key = e.target.name;

            if (!isChecked) {
                dispatch(Actions.removeFromSignupForm([key]));
                return;
            }

            val = BOOLEAN_VALUES[isChecked];
        }

        if (formData[name]?.keyboardType === "tel" && !isNumber(val) && val?.length > 0) {
            return;
        }

        dispatch(Actions.updateSignupForm({ [key]: val }));
    };

    const handleNextButtonClick = (e) => {
        if (e) {
            e.preventDefault();
        }

        setFirstTry(false);

        let validationResult = validateForm(true);

        if (validationResult) {
            const onSuccess = (response) => {
                if (response.status === 1) {
                    const { data } = response;
                    const token = data.token;
                    setOtpToken(token);
                    setIsOtpSent(true);
                    setNumberOfOtpTries(0);
                    setNumberOfResend(0);
                    dispatch(Actions.setOtpStateToken(token));
                }
            };

            const onFailure = (response) => {
                if (response.status === 0) {
                    const errorId = parseInt(response.err.id);

                    if (errorId === 1) {
                        handleTooManyOtpTries();
                        return;
                    }

                    handleServerError(response);
                }
            };

            const props = {
                onSuccess,
                onFailure,
                payload: {
                    phone: signupForm[fields.phoneNumber.key],
                    // type: signupForm[fields.phoneValidationCheckbox.key] ? 2 : 1,
                },
            };

            // * GET OTP ON NEXT BUTTON CLICK
            Api.getOtp(props);
        }
    };

    const handleOtpChange = (otpCode) => {
        setOtpCode(otpCode);
        let otpCodeObjectLength = otpCode.length;

        if (otpCodeObjectLength === otpInputs) {
            handleValidateOtpCode(null, otpCode);
        }
    };

    const resendOtpCode = () => {
        setNumberOfResend((prevState) => prevState + 1);

        if (!canResend) {
            return;
        }

        setFirstTry(true);
        setOtpCode("");
        setOtpToken("");
        dispatch(Actions.setOtpStateToken(false));
        setIsResendOtp(true);

        const onSuccess = (response) => {
            setIsResendOtp(false);
            if (response.status === 1) {
                const { data } = response;
                const token = data.token;
                setOtpToken(token);
                dispatch(Actions.setOtpStateToken(token));
            }
        };

        const onFailure = (response) => {
            setIsResendOtp(false);

            if (response.status === 0) {
                handleServerError(response);
            }
        };

        const props = {
            onSuccess,
            onFailure,
            payload: {
                phone: signupForm[fields.phoneNumber.key],
            },
        };

        // * GET OTP ON RESEND BUTTON CLICK
        Api.getOtp(props);
    };

    const setOtpSubLabel = () => {
        const otpSubLabel = translate(fields.otpCode.resendLabel);
        const phoneNumber = signupForm[fields.phoneNumber.key];
        const phoneNumberLength = phoneNumber.length;

        // * CHANGING FIRST DIGITS OF A PHONE NUMBER WITH ASTERISKS AND KEEPS ONLY LAST X (BASED ON A PARAMETER FROM GD) DIGITS SHOWN
        const hiddenPhoneNumberPart = phoneNumber.substring(numberOfShownDigits, phoneNumberLength).replace(/[0-9]/g, "*");
        const shownPhoneNumberPart = phoneNumber.substring(phoneNumberLength - numberOfShownDigits);
        const updatedPhoneNumber = shownPhoneNumberPart + hiddenPhoneNumberPart;

        return otpSubLabel + updatedPhoneNumber;
    };

    const smsOtpCodeListener = () => {
        if ("OTPCredential" in window) {
            const ac = new AbortController();

            navigator.credentials
                .get({
                    otp: { transport: ["sms"] },
                    signal: ac.signal,
                })
                .then((otp) => {
                    const otpValue = String(otp.code);
                    handleOtpChange(otpValue);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    };

    const handleValidateOtpCode = (e, code = otpCode) => {
        if (e) {
            e.preventDefault();
        }

        if (!code) {
            return;
        }

        let otpCodeObjectLength = code.length;
        let newState = { ...formData };

        setFirstTry(false);

        if (otpCodeObjectLength !== otpInputs) {
            newState[fields.otpCode.name].isValid.valid = false;
            newState[fields.otpCode.name].isValid.msg = translate(fields.otpCode.lengthDoesNotMatchError);

            setFormData(newState);
            setOtpCode("");
            return;
        }

        newState[fields.otpCode.name].isValid.valid = true;
        newState[fields.otpCode.name].isValid.msg = "";
        setFormData(newState);

        if (typeof grecaptcha !== "undefined") {
            grecaptcha.ready(function () {
                grecaptcha.execute(process.env.REACT_APP_GOOGLE_RECAPTCHA, { action: "submit" }).then(function (token) {
                    const onSuccess = (response) => {
                        if (response.status === 1) {
                            const { data } = response;

                            // IF `end_process` EXISTS => IT MEANS THE REGISTRATION IS DONE AND THEREFORE THE USER MUST BE REDIRECTED TO THE LAST PAGE
                            if (data?.end_process === BOOLEAN_VALUES.true) {
                                const lastPagePath = `/${mainRootRoutes.agreementTerm}/${routesMap.agreementTerm.subRoutes.congratulations.path}`;
                                dispatch(Actions.setIsLeadComplete(BOOLEAN_VALUES.true));
                                dispatch(Actions.setWizardSteps({ isBackDisabled: BOOLEAN_VALUES.true }));
                                navigate(lastPagePath);
                                return;
                            }

                            dispatch(Actions.updateSignupForm(data));
                            dispatch(Actions.resetOtpState());

                            // IF `end_authentication_id` EXISTS => IT MEANS USER ALREADY UPLOADED ID INFO AND THEREFORE MUST BE REDIRECTED TO AGREEMENTS PAGE
                            if (data?.end_authentication_id === BOOLEAN_VALUES.true) {
                                const generalAgreementTerms = `/${mainRootRoutes.agreementTerm}/${routesMap.agreementTerm.subRoutes.general.path}`;
                                dispatch(Actions.setWizardSteps({ isBackDisabled: BOOLEAN_VALUES.true }));
                                navigate(generalAgreementTerms);
                                return;
                            }

                            let validationPath = validateRegistrationData(data);
                            navigateToNextStep(validationPath, location, navigate);
                        }
                    };

                    const onFailure = (response) => {
                        if (response.status === 0) {
                            setNumberOfOtpTries((prevState) => (prevState += 1));

                            newState[fields.otpCode.name].isValid.valid = false;
                            newState[fields.otpCode.name].isValid.msg = translate(fields.otpCode.wrongCodeError);
                            setFormData(newState);
                            setOtpCode("");
                        }
                    };

                    const props = {
                        onSuccess,
                        onFailure,
                        payload: {
                            reCaptcha: token,
                            token: otpToken,
                            code: code,
                        },
                    };

                    Api.validOtp(props);
                });
            });
        }
    };

    return (
        <>
            {!isOtpSent ? (
                <form className="otp-validation-wrapper" onSubmit={handleNextButtonClick}>
                    <div className="otp-validation-data">
                        <HeaderTitle>{translate("otp_phone_number_main_header")}</HeaderTitle>
                        <Subheader>{translate("otp_phone_number_header")}</Subheader>

                        <TextInput
                            label={translate(fields.phoneNumber.label)}
                            placeholder={translate(fields.phoneNumber.placeholder)}
                            name={fields.phoneNumber.name}
                            value={signupForm[fields.phoneNumber.key] || ""}
                            onChange={handleInputChange}
                            showError={!firstTry && formData[fields.phoneNumber.name].isValid?.valid === false}
                            errorMessage={formData[fields.phoneNumber.name].isValid?.errMsg}
                            type={fields.phoneNumber.type}
                            keyboardType={fields.phoneNumber.keyboardType}
                            disabled={isNumberDisabled || !leadId}
                            maxLength={10}
                        />

                        {/* // ! CURRENTLY THIS IS NOT WORKING SO IT'S DISABLED */}
                        {/* <Checkbox
                            type={fields.phoneValidationCheckbox.type}
                            id={fields.phoneValidationCheckbox.id}
                            name={fields.phoneValidationCheckbox.name}
                            label={translate(fields.phoneValidationCheckbox.label)}
                            onChange={handleInputChange}
                            showError={!firstTry && formData[fields.phoneValidationCheckbox.name].isValid?.valid === false}
                            errorMessage={formData[fields.phoneValidationCheckbox.name].isValid?.errMsg}
                            checked={signupForm[fields.phoneValidationCheckbox.name] === BOOLEAN_VALUES.true}
                            disabled={!leadId}
                        /> */}
                    </div>

                    <Button isNext={true} onClick={handleNextButtonClick} disabled={isNumberDisabled || !leadId}>
                        <span>{translate("send_otp_code_button")}</span>
                    </Button>
                </form>
            ) : (
                <form className="otp-validation-wrapper" onSubmit={handleValidateOtpCode}>
                    <div className="wizard-otp">
                        <HeaderTitle>{translate("otp_is_sent_header")}</HeaderTitle>

                        <OtpInput
                            numberOfInputs={otpInputs}
                            showError={!firstTry && formData[fields.otpCode.name].isValid.valid === false}
                            errorMessage={formData[fields.otpCode.name].isValid.msg}
                            onChange={handleOtpChange}
                            value={otpCode}
                            subLabel={setOtpSubLabel()}
                            enterCodeLabel={translate("enter_otp_code_label")}
                            resendOtpFunc={resendOtpCode}
                            isResendOtp={isResendOtp}
                            resendOtpObject={{ main: translate("otp_did_not_receive_code"), action: translate("otp_send_again") }}
                            sendingOtpText={translate("otp_resending_code_text")}
                            canResend={canResend}
                        />
                    </div>

                    <Button isNext={true} onClick={handleValidateOtpCode} />
                </form>
            )}
        </>
    );
};

export default OtpValidation;
