import { previousPageObject } from "app/functions";
import useTranslate from "app/hooks/useTranslate";
import backArrow from "assets/images/back-arrow.svg";
import closeIcon from "assets/images/close-icon-white.svg";
import growLogo from "assets/images/grow-logo.svg";
import { BOOLEAN_VALUES } from "constants/input-fields";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import Actions from "redux/actions";
import "./index.scss";

function Header() {
    const loaderState = useSelector((store) => store.loaderState);
    const wizardSteps = useSelector((store) => store.wizardSteps);
    const isIframe = useSelector((store) => store.isIframe);
    const isLeadOccupationEdit = useSelector((store) => store.isLeadOccupationEdit);
    const translate = useTranslate();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const isLastStep = wizardSteps?.isLastStep === BOOLEAN_VALUES.true;
    const isBackDisabled = wizardSteps?.isBackDisabled === BOOLEAN_VALUES.true;

    const handleBackButtonClick = () => {
        // * DISABLE CLICK WHEN LOADER IS SHOWN
        if (loaderState) {
            return;
        }

        let result = previousPageObject(location);
        if (Object.keys(result).length > 0) {
            let mainPath = result?.main;
            let subRoutePath = result?.sub;

            if (mainPath && subRoutePath) {
                let fullPath = `/${mainPath}/${subRoutePath}`;
                navigate(fullPath);
            }
        }
    };

    const handleCloseFormButtonClick = () => {
        if (isIframe) {
            window.parent.postMessage({ action: "close", source: "growRegister" }, "*");
        }
    };

    useEffect(() => {
        let pageObjectResponse = previousPageObject(location);

        if (pageObjectResponse) {
            dispatch(Actions.setWizardSteps({ currentStep: pageObjectResponse.step }));
        }
    }, [location]);

    return (
        <header className="header-container">
            <div className="header-wrapper">
                {wizardSteps.currentStep > 1 && !isLastStep && !isBackDisabled && !isLeadOccupationEdit && (
                    <div className="header-back-wrapper" onClick={handleBackButtonClick}>
                        <button className="header-back-button">
                            <img src={backArrow} alt={translate("back_button_alt_text")} />
                        </button>

                        <span>{translate("header_go_back")}</span>
                    </div>
                )}

                {isIframe ? (
                    <div className="header-close-logo-wrapper">
                        <GrowLogo />

                        {!isLeadOccupationEdit && (
                            <button className="header-close-button" onClick={handleCloseFormButtonClick}>
                                <img src={closeIcon} alt={translate("close_button_image_alt")} />
                            </button>
                        )}
                    </div>
                ) : (
                    <GrowLogo />
                )}
            </div>
        </header>
    );
}

const GrowLogo = () => {
    const translate = useTranslate();

    return (
        <div className="header-grow-logo">
            <img src={growLogo} alt={translate("grow_logo_alt")} />
        </div>
    );
};

export default Header;
