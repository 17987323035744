import axios from "axios/index";
import store from "../redux/index";

import Actions from "redux/actions";
import popupTypes from "constants/popup-types";

import QueueManager from "./queue_manager";

class ApiManager {
    /* ------ private variables ------- */
    #api = false;
    #queue = false;

    constructor() {
        this.#api = {
            https: true,
            baseUrl: process.env.REACT_APP_HOST,
            parts: {
                path: process.env.REACT_APP_PATH,
                platform: process.env.REACT_APP_PLATFORM,
                version: process.env.REACT_APP_VERSION,
            },
            getMethodsUrl: process.env.REACT_APP_GET_HOST,
            getMethodsList: [],
        };

        this.#queue = new QueueManager();
    }

    /* ------ private methods ------- */

    #buildBaseUrl = (useGetUrl = false) => {
        let path = "/";
        for (let part in this.#api.parts) {
            let item = this.#api.parts[part];
            if (item !== "") {
                path = path + item + "/";
            }
        }

        //use package json proxy while developing in localhost
        if (process.env.NODE_ENV === "development") {
            return path;
        }

        let url = (this.#api.https ? "https" : "http") + "://" + (useGetUrl ? this.#api.getMethodsUrl : this.#api.baseUrl);
        url = url + path;
        return url;
    };

    #call = (settings, onSuccess, onFailure, onCatch, callback) => {
        return axios(settings)
            .then((response) => {
                store.dispatch(Actions.requestEnded());
                this.#queue.popFromQueue();
                if (typeof callback === "function") {
                    callback(response);
                }
                if (response.data.status) {
                    onSuccess ? onSuccess(response.data) : this.#onSuccess();
                    return response.data;
                } else {
                    onFailure ? onFailure(response.data) : this.#onFailure(response.data);
                }
            })
            .catch((error) => {
                store.dispatch(Actions.requestEnded());
                console.log("catch error:", error);
                this.#handleServerError(error, onCatch);
            });
    };

    #generateRequest = (request, params = {}, method = "post", timeout = false) => {
        let requestUrl = request;
        let data = new FormData();
        let settings = {};

        //build form data
        if (!(Object.entries(params).length === 0 && params.constructor === Object)) {
            for (let key in params) {
                data.append(key, params[key]);
            }
        } else {
            data = null;
        }

        if (this.#api.debug_mode) {
            console.log("%cmaking request:" + requestUrl, "color: #0000FF", "");
            console.log("%c---request payload:", "color: #0000FF", params);
        }

        settings.method = method;
        settings.url = requestUrl;
        settings.timeout = timeout ? timeout : 1000 * 60 * 2;
        settings.withCredentials = true;
        settings.headers = {
            "x-api-key": process.env.REACT_APP_X_API_KEY,
        };
        settings.data = params;

        // method === 'post' ? settings.data = data : settings.params = params;

        const hideLoader = settings.data.hideLoader;

        if (!hideLoader) {
            store.dispatch(Actions.requestStarted());
        }

        delete settings.data.hideLoader;
        return settings;
    };

    #onSuccess = () => {
        console.log("successful");
    };

    #onFailure = (response) => {
        const text = response?.err?.content !== undefined ? response.err.content : "תקלת שרת, אנא נסה שנית מאוחר יותר";
        store.dispatch(
            Actions.addPopup({
                type: popupTypes.API_ERROR,
                payload: { text: text },
            })
        );
    };

    #handleServerError = (error, onCatch) => {
        if (onCatch && typeof onCatch === "function") {
            onCatch(error);
            return;
        }

        store.dispatch(Actions.addPopup({ type: popupTypes.API_MESSAGE, text: error.message }));
    };

    /* ------ protected methods ------- */

    _execute = (props, methodName, onSuccess = false, onFailure = false, onCatch = false) => {
        let request, method;
        let inGetMethodArr = this.#api.getMethodsList.find((method) => method === methodName);

        if (inGetMethodArr) {
            method = "get";
            request = this.#buildBaseUrl(true) + methodName;
        } else {
            method = "post";
            request = this.#buildBaseUrl() + methodName;
        }

        //override if props sent
        let override_path = props?.config?.path !== undefined;
        let override_method = props?.config?.method !== undefined;
        if (override_method) {
            method = props.config.method;
        }
        if (override_path) {
            request = props.config.path + methodName;
        }

        const block = props?.config?.block !== undefined;
        const settings = this.#generateRequest(request, props.payload, method);

        this.#queue.addRequestToQueue(() => this.#call(settings, onSuccess, onFailure, onCatch, props.callback), block);

        if (!block) return this.#call(settings, onSuccess, onFailure, onCatch, props.callback);
    };

    _updateApiParams = (api) => {
        if (api.base_url) this.#api.baseUrl = api.base_url;
        if (api.get_methods) this.#api.getMethodsList = api.get_methods;
        if (api.get_url) this.#api.getMethodsUrl = api.get_url;
    };
}

export default ApiManager;
